import React, { Component } from "react";
import { useSearchParams } from "react-router-dom";
import Form from "../../components/Form";
import Page from "../../components/Page";
import axios from "axios";
import copy from "copy-text-to-clipboard";
import Format from "../../lib/format";
import { useAuth } from "../../lib/auth";
import SuperLink from "../../components/SuperLink";
import Clock from "../../lib/clock";

class EventDetailsPage extends Component {

  componentDidMount() {
    document.title = "Event Register | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <h1>Event Register</h1>
        <DetailsFormWrapper/>
      </Page>
    );
  }

}

function DetailsFormWrapper(props) {
  let auth = useAuth();
  let searchParams = useSearchParams()[0];
  return <DetailsForm {...props} auth={auth} id={searchParams.get("id")}/>
}

class DetailsForm extends Component {

  constructor(props) {
    super(props); // auth, id
    this.state = {info: {}, signups: [], loaded: 0};
    this.populateInfo();
    this.populateSignups();
  }

  populateInfo() {
    axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/event/info`, {
      params: {
        id: this.props.id
      },
      headers: {
        'Authorization': `Bearer ${this.props.auth.getToken()}` 
      }
    })
    .then(response => this.setState({info: response.data, loaded: this.state.loaded+1}))
  }

  populateSignups() {
    axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/event/register`, {
      params: {
        id: this.props.id
      },
      headers: {
        'Authorization': `Bearer ${this.props.auth.getToken()}` 
      }
    })
    .then(response => this.setState({signups: response.data, loaded: this.state.loaded+1}))
  }

  participantAction(event, studentNumber, option) {
    event.preventDefault();
    axios.put(`/api/v${process.env.REACT_APP_API_VERSION}/event/rollcall`, 
      {
        id: this.props.id,
        studentNumber,
        option
      },
      {
        headers: {
          'Authorization': `Bearer ${this.props.auth.getToken()}` 
        }
      })
    .then(() => {
      if (["present", "absent", "clear"].includes(option)) {
        let signups = this.state.signups;
        signups = signups.map(s => {
          if (s.studentNumber === studentNumber) {
            switch (option) {
              case "present":
                s.attended = 1;
                break;
              case "absent":
                s.attended = 0;
                break;
              default:
                s.attended = null;
                break;
            }
          }
          return s;
        })
        this.setState({signups});
      } else {
        this.populateSignups();
      }
    })
  }

  getColumnVisibility(column) {
    if (this.getFormVersion() === "populated") {
      const start = this.state.info.startTimestamp
      const end = this.state.info.endTimestamp
      // hide attendance columns more than 30 minutes before an event
      if (Clock.time() < start - (30 * 60 * 1000) 
        && ["attendance-status", "attendance-actions"].includes(column)) {
        return false; 
      // hide all modification options more than 7 days after an event (unless have permission to bypass lock)
      } else if (Clock.time() > end + (7 * 24 * 60 * 60 * 1000) 
        && ["attendance-actions", "list-actions"].includes(column)
        && !["chairperson", "webmaster"].includes(this.props.auth.getRole())) { // TODO: replace role checking for permission checking (should cache permissions - in token?)
        return false;
      // hide all modification options if lacking appropriate permissions
      } else if (["attendance-actions", "list-actions"].includes(column)
        && !["sub-committee", "general-committee", "executive-committee", "chairperson", "webmaster"].includes(this.props.auth.getRole())) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  copyEmails(category) {
    let emails = "";
    const signups = this.state.signups.filter(s => category === s.status);
    signups.forEach(s => emails += s.email + ", ");
    emails = emails.substring(0, emails.length-2);
    copy(emails);
  }

  getFormVersion() {
    if (this.state.loaded < 2) {
      return "loading";
    }
    if (this.state.signups.length > 0) {
      return "populated";
    } else {
      return "empty";
    }
  }

  getFormStructure() {
    if (this.getFormVersion() === "loading") {
      return this.getLoadingStructure();
    }
    if (this.getFormVersion() === "populated") {
      return this.getPopulatedStructure();
    } else {
      return this.getEmptyStructure();
    }
  }

  getPopulatedStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              label: "Information",
              fields: [
                {
                  type: "text",
                  attributes: {
                    id: "event-title",
                    label: "Title",
                    default: this.state.info.title,
                    disabled: true
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "event-date",
                    label: "Date",
                    default: Format.dates(this.state.info.startTimestamp, this.state.info.endTimestamp),
                    disabled: true
                  }
                }
              ]
            },
            {
              label: "Options",
              fields: [
                {
                  type: "regularbutton",
                  attributes: {
                    id: "copy-guaranteed-button",
                    label: "Copy guaranteed list emails",
                    onClick: () => {this.copyEmails("going");}
                  }
                },
                {
                  type: "regularbutton",
                  attributes: {
                    id: "copy-waiting-button",
                    label: "Copy waiting list emails",
                    onClick: () => {this.copyEmails("waiting");}
                  }
                }
              ]
            },
            {
              label: "Participants",
              fields: [
                {
                  type: "table",
                  attributes: {
                    id: "parcipant-table",
                    columns: [
                      {title: "#"}, 
                      {title: "Student Number"}, 
                      {title: "Name"}, 
                      {title: "Contact"},
                      {title: "Driver"}, 
                      {title: "Passengers"}, 
                      {title: "Comment"},
                      {title: "Attended", visible: this.getColumnVisibility("attendance-status")},
                      {title: "Attendance Actions", visible: this.getColumnVisibility("attendance-actions")},
                      {title: "List Actions", visible: this.getColumnVisibility("list-actions")}
                    ],
                    rows: this.state.signups.map((props, idx) => {
                      const dropActionLink = (<SuperLink to="#" onClick={(e) => {this.participantAction(e, props.studentNumber, "drop");}} icon="delete">Drop</SuperLink>);
                      const addActionLink = (<SuperLink to="#" onClick={(e) => {this.participantAction(e, props.studentNumber, "add");}} icon="add">Return</SuperLink>);
                      const preferActionLink = (<SuperLink to="#" onClick={(e) => {this.participantAction(e, props.studentNumber, "prefer");}} icon="star">Give Spot</SuperLink>);
                      const retractActionLink = (<SuperLink to="#" onClick={(e) => {this.participantAction(e, props.studentNumber, "retract");}} icon="denied">Retract Spot</SuperLink>);
                      const presentActionLink = (<SuperLink to="#" onClick={(e) => {this.participantAction(e, props.studentNumber, "present");}} icon="tick">Present</SuperLink>);
                      const absentActionLink = (<SuperLink to="#" onClick={(e) => {this.participantAction(e, props.studentNumber, "absent");}} icon="cross">Absent</SuperLink>);
                      const eraseActionLink = (<SuperLink to="#" onClick={(e) => {this.participantAction(e, props.studentNumber, "clear");}} icon="erase">Clear</SuperLink>);
                      let attendanceActionLinks = (<></>);
                      let listActionLinks = (<></>);
                      let highlightColor;
                      switch (props.status) {
                        case "preferred":
                          highlightColor = "darkgreen";
                          attendanceActionLinks = (
                            <>
                              {presentActionLink}
                              <br/>
                              {absentActionLink}
                              <br/>
                              {eraseActionLink}
                            </>
                          );
                          listActionLinks = (
                            <>
                              {retractActionLink}
                              <br/>
                              {dropActionLink}
                            </>
                          );
                          break;
                        case "going":
                          highlightColor = "lightgreen";
                          attendanceActionLinks = (
                            <>
                              {presentActionLink}
                              <br/>
                              {absentActionLink}
                              <br/>
                              {eraseActionLink}
                            </>
                          );
                          listActionLinks = (
                            <>
                              {dropActionLink}
                            </>
                          );
                          break;
                        case "waiting":
                          highlightColor = "yellow";
                          listActionLinks = (
                            <>
                              {preferActionLink}
                              <br/>
                              {dropActionLink}
                            </>
                          );
                          break;
                        case "dropped":
                          highlightColor = "grey";
                          listActionLinks = (
                            <>
                              {addActionLink}
                            </>
                          );
                          break;
                        default:
                          highlightColor = "grey";
                          break;
                      }
                      if (["preferred", "going"].includes(props.status)) {
                        if (props.attended === 1) {
                          attendanceActionLinks = (
                            <>
                              {absentActionLink}
                              <br/>
                              {eraseActionLink}
                            </>
                          );
                        } else if (props.attended === 0) {
                          attendanceActionLinks = (
                            <>
                              {presentActionLink}
                              <br/>
                              {eraseActionLink}
                            </>
                          );
                        } else {
                          attendanceActionLinks = (
                            <>
                              {presentActionLink}
                              <br/>
                              {absentActionLink}
                            </>
                          );
                        }
                      }
                      return {id: props.id, cells: [
                        {content: idx+1, highlight: highlightColor},
                        {content: props.studentNumber, highlight: highlightColor},
                        {content: props.name, highlight: highlightColor},
                        {content: "<span style='font-weight: bold;'>Email:</span>&nbsp;" + props.email + "<br/><span style='font-weight: bold;'>Phone:</span>&nbsp;" + props.phone + "<br/><span style='font-weight: bold;'>Emergency:</span>&nbsp;" + props.emergency, highlight: highlightColor},
                        {content: props.driver === 1 ? "Yes" : "No", highlight: highlightColor},
                        {content: props.passengers, highlight: highlightColor},
                        {content: props.comment, highlight: highlightColor},
                        {content: props.attended === 1 ? "Yes" : (props.attended === 0 ? "No" : ""), highlight: highlightColor, visible: this.getColumnVisibility("attendance-status")},
                        {content: attendanceActionLinks, highlight: highlightColor, visible: this.getColumnVisibility("attendance-actions")},
                        {content: listActionLinks, highlight: highlightColor, visible: this.getColumnVisibility("list-actions")}
                      ]}
                    })
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  getEmptyStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              label: "Information",
              fields: [
                {
                  type: "text",
                  attributes: {
                    id: "event-title",
                    label: "Title",
                    default: this.state.info.title,
                    disabled: true
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "event-date",
                    label: "Date",
                    default: Format.dates(this.state.info.startTimestamp, this.state.info.endTimestamp),
                    disabled: true
                  }
                }
              ]
            },
            {
              label: "Participants",
              fields: [
                {
                  type: "information",
                  attributes: {
                    id: "info",
                    title: "No Participants",
                    text: "There are currently no members signed up for this event, please check again later."
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  getLoadingStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              fields: [
                {
                  type: "information",
                  attributes: {
                    id: "info",
                    title: "Loading Event...",
                    text: "Please wait while the event information is loaded."
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  render() {
    return <Form structure={this.getFormStructure()} version={this.getFormVersion()} width="1000px"/>;
  }

}

export default EventDetailsPage;