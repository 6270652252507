import React, { Component } from 'react';
import Wrapper from './Wrapper';

import clsx from 'clsx';

import styles from './styles/text.module.css';

class TextField extends Component {

  constructor(props) {
    super(props); // id, label, value, placeholder, required, disabled, valid, visible, onChange, onBlur, onEnter
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(e) {
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.props.id, e.target.value);
    }
  }

  handleBlur(e) {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(this.props.id, e.target.value);
    }
  }

  handleKeyPress(e) {
    if (this.props.onEnter !== undefined) {
      var keyCode = e.code || e.key;
      if (keyCode === 'Enter'){
        this.props.onEnter(this.props.id, e.target.value);
        return false;
      }
    }
  }

  render() {
    return (
      <Wrapper 
        label={this.props.label}
        for={this.props.id}
        required={this.props.required}
        disabled={this.props.disabled}
        valid={this.props.valid}
        visible={this.props.visible}>
        <input
          id={this.props.id}
          name={this.props.id}
          type='text'
          className={clsx({
            [styles.input]: true,
            [styles.invalid]: !this.props.valid
          })}
          value={this.props.value === null ? "" : this.props.value}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled != null && this.props.disabled}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyPress={this.handleKeyPress}/>
      </Wrapper>
    );
  }

}

export default TextField;