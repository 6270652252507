import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import AuthProvider, { RequireAuth } from './lib/auth';
import App from './app';
import HomePage from "./routes/index";
import LoginPage from "./routes/login"
import EventSignUpPage from "./routes/event/signup"
import EventManagerPage from './routes/event/manager';
import EventCreatePage from './routes/event/create';
import EventEditPage from './routes/event/edit';
import EventDetailsPage from './routes/event/details';
import Error404Page from './routes/404';
import AnnouncementGeneratorPage from './routes/announcement/generator';

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App/>}>
          <Route index element={<RequireAuth><HomePage/></RequireAuth>}/>
          <Route path="login" element={<LoginPage/>}/>
          <Route path="announcement/generator" element={<RequireAuth roles={["chairperson", "webmaster"]}><AnnouncementGeneratorPage/></RequireAuth>}/>
          <Route path="event/signup" element={<EventSignUpPage/>}/>
          <Route path="event/manager" element={<RequireAuth><EventManagerPage/></RequireAuth>}/>
          <Route path="event/create" element={<RequireAuth><EventCreatePage/></RequireAuth>}/>
          <Route path="event/edit" element={<RequireAuth><EventEditPage/></RequireAuth>}/>
          <Route path="event/details" element={<RequireAuth><EventDetailsPage/></RequireAuth>}/>
          <Route path="*" element={<Error404Page/>}/>
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);