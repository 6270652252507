import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../lib/auth";
import clsx from 'clsx';

import styles from './superlink.module.css';

function SuperLink(props) {

  // props: to, onClick, icon, roles, ids, access, denyStyle, breakAfter

  let auth = useAuth();
  let hasAccess = false;
  if (
    (props.roles == null && props.ids == null) ||
    (props.roles != null && props.roles.includes(auth.getRole())) ||
    (props.ids != null && props.ids.includes(auth.getClaim("sub")))
  ) {
    hasAccess = true;
  }
  if (props.access != null && hasAccess) {
    hasAccess = props.access;
  }
  let denyStyle = "show";
  if (props.denyStyle === "hide") {
    denyStyle = "hide";
  }
  let breakBefore = false;
  if (props.breakBefore === true) {
    breakBefore = true;
  }
  let breakAfter = false;
  if (props.breakAfter === true) {
    breakAfter = true;
  }

  let authPopup = function(e) {
    e.preventDefault();
    Swal.fire({
      title: "Access Denied",
      text: "You do not have the required permissions to access this link.",
      icon: "error",
      confirmButtonColor: "#1C53A4"
    });
  }

  let hasIcon = false;
  let key = "";
  if (props.icon != null) {
    key = "icon" + props.icon.charAt(0).toUpperCase() + props.icon.slice(1);
    if (styles[key] != null) {
      hasIcon = true;
    }
  }
  
  if (hasAccess && hasIcon) {
    return (<>{breakBefore ? <br/> : <></>}<Link className={styles.normal} to={props.to} onClick={props.onClick}><div className={clsx(styles.icon, styles[key])}></div>{props.children}</Link>{breakAfter ? <br/> : <></>}</>);
  } else if (hasAccess) {
    return (<>{breakBefore ? <br/> : <></>}<Link className={styles.normal} to={props.to} onClick={props.onClick}>{props.children}</Link>{breakAfter ? <br/> : <></>}</>);
  } else {
    if (denyStyle === "show") {
      return (<>{breakBefore ? <br/> : <></>}<Link className={styles.restricted} to={props.to} title="Access Denied" onClick={authPopup}><div className={clsx(styles.icon, styles.iconDenied)}></div>{props.children}</Link>{breakAfter ? <br/> : <></>}</>);
    } else {
      return <></>;
    }
  }
}

export default SuperLink;