import axios from "axios";

class Clock {

  static client;
  static history;
  static delta;
  static interval;

  static {
    this.client = axios.create();
    this.history = [];
    this.delta = 0;
    this.interval = null;
    this.init();
  }

  static init() {
    // add interceptors to custom axios instance to track response duration
    this.client.interceptors.request.use(
      function(config) {
        config.metadata = {startTime: Date.now()};
        return config;
      }, 
      function(error) {
        return Promise.reject(error);
      }
    );
    this.client.interceptors.response.use(
      function(response) {
        response.config.metadata.endTime = Date.now();
        response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
        return response;
      }, 
      function(error) {
        error.config.metadata.endTime = Date.now();
        error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
        return Promise.reject(error);
      }
    );

    // perform initial calibration
    this.calibrate();

    // recalibrate clock every 30 seconds
    this.interval = setInterval(function(){this.calibrate()}.bind(this), 30000);
  }

  static average(array) {
    return array.reduce((a, b) => a + b) / array.length;
  }

  static calibrate() {
    this.client.get(`/api/v${process.env.REACT_APP_API_VERSION}/server/time`)
      .then(function(response) {
        const delta = Date.now() - response.data.timestamp - (response.duration / 2);
        this.history.push(delta);
        this.delta = Math.round(this.average(this.history));
      }.bind(this));
    }

  static time() {
    return Date.now() - this.delta;
  }

  static destroy() {
    clearInterval(this.interval);
  }

}

export default Clock;