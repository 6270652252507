import { Component } from "react";
import Form from "../../components/Form";
import Page from "../../components/Page";
import axios from "axios";
import Swal from "sweetalert2";

class EventSignUpPage extends Component {

  componentDidMount() {
    document.title = "Event Sign-Ups | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <h1>Event Sign-Ups</h1>
        <SignUpForm/>
      </Page>
    );
  }

}

class SignUpForm extends Component {

  constructor(props) {
    super(props);
    this.state = {events: [], loaded: false}
    this.interval = null;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchEvents();
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.fetchEvents();
    }, 5000);
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchEvents() {
    axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/event/list`, {params: {
      filter: "signup"
    }})
    .then(response => this.setState({events: response.data, loaded: true}));
  }

  getFormVersion() {
    if (!this.state.loaded) {
      return "loading";
    }
    if (this.state.events.length > 0) {
      return "populated";
    } else {
      return "empty";
    }
  }

  getFormStructure() {
    if (!this.state.loaded) {
      return this.getLoadingStructure();
    }
    if (this.state.events.length > 0) {
      return this.getPopulatedStructure();
    } else {
      return this.getEmptyStructure();
    }
  }

  getPopulatedStructure() {
    return {
      majorSections: [
        {
          label: "What would you like to sign up for?",
          fields: [
            {
              type: "eventselect",
              attributes: {
                id: "event",
                events: this.state.events,
                required: true
              }
            }
          ]
        },
        {
          label: "Who are you?",
          minorSections: [
            {
              label: "Contact Information",
              fields: [
                {
                  type: "text",
                  attributes: {
                    id: "student_id",
                    label: "Student Number",
                    validate: "^([A-Za-z]{6}[0-9]{3})$",
                    required: true
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "name",
                    label: "Name + Surname",
                    required: true
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "email",
                    label: "Email Address",
                    validate: "email",
                    required: true
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "phone",
                    label: "Cell Number",
                    validate: "phone",
                    required: true
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "emergency",
                    label: "Emergency Contact Number",
                    validate: "phone",
                    required: true
                  }
                }
              ]
            },
            {
              label: "Driving Information",
              fields: [
                {
                  type: "radioselect",
                  attributes: {
                    id: "driver",
                    label: "Are you able to give lifts?",
                    required: true,
                    options: [
                      {label: "Yes", value: 1, icon: "tick"},
                      {label: "No", value: 0, icon: "cross"}
                    ]
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "passengers",
                    label: "How many people can you take in your car?",
                    validate: "int[1-10]",
                    required: true,
                    visible: "driver={1}"
                  }
                }
              ]
            },
            {
              label: "Additional Information",
              fields: [
                {
                  type: "text",
                  attributes: {
                    id: "comment",
                    label: "Other Comments"
                  }
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          type: "submitbutton"
        }
      ]
    };
  }

  getEmptyStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              fields: [
                {
                  type: "information",
                  attributes: {
                    id: "info",
                    title: "No Events Available",
                    text: "There are currently no events accepting sign-ups, please check again later."
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  getLoadingStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              fields: [
                {
                  type: "information",
                  attributes: {
                    id: "info",
                    title: "Loading Events...",
                    text: "Please wait while the events accepting sign-ups are loaded."
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  handleSubmit(valid, fields) {
    if (valid) {
      Swal.fire({
        title: "Submitting...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      axios.post(`/api/v${process.env.REACT_APP_API_VERSION}/event/signup`, {
        id: fields["event"].value,
        studentNumber: fields["student_id"].value,
        name: fields["name"].value,
        email: fields["email"].value,
        phone: fields["phone"].value,
        emergency: fields["emergency"].value,
        driver: fields["driver"].value,
        passengers: fields["passengers"].value || 0,
        comment: fields["comment"].value
      })
      .then(response => {
        Swal.close();
        switch (response.data.status) {
          case "going":
            Swal.fire({
              title: "Sign-Up Confirmed",
              text: "You have successfully claimed a guaranteed spot on this event!",
              icon: "success",
              confirmButtonColor: "#1C53A4"
            });
            break;
          case "waiting":
            Swal.fire({
              title: "Sign-Up Pending",
              text: "You have claimed a spot on the waiting list for this event.",
              icon: "warning",
              confirmButtonColor: "#1C53A4"
            });
            break;
          case "repeated":
            Swal.fire({
              title: "Duplicate Sign-Up",
              text: "You have already submitted a sign-up for this event.",
              icon: "info",
              confirmButtonColor: "#1C53A4"
            });
            break;
          case "rejected":
            Swal.fire({
              title: "Sign-Up Failed",
              text: "All the spots on this event have already been filled.",
              icon: "error",
              confirmButtonColor: "#1C53A4"
            });
            break;
          case "missed":
          case "failed":
          default:
            Swal.fire({
              title: "Sign-Up Failed",
              text: "Sign-ups for this event have been closed.",
              icon: "error",
              confirmButtonColor: "#1C53A4"
            });
            break;
        }
      })
      .catch((error) => {
        Swal.close();
        Swal.fire({
          title: "Sign-Up Failed",
          text: "Your sign-up was not accepted, please try again.",
          icon: "error",
          confirmButtonColor: "#1C53A4"
        });
        console.log(error);
      });
    }
  }

  render() {
    return <Form structure={this.getFormStructure()} version={this.getFormVersion()} onSubmit={this.handleSubmit}/>;
  }

}

export default EventSignUpPage;