import React, { Component } from 'react';

import styles from './buttons.module.css';

class SubmitButton extends Component {

  constructor(props) {
    super(props); // label, onClick
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick();
  }

  render() {
    return (
      <button
        className={styles.submit}
        onClick={this.handleClick}>
        {this.props.label == null ? "Submit" : this.props.label}
      </button>
    );
  }

}

export default SubmitButton;