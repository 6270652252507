import React, { Component } from "react";
import Form from "../Form";
import Format from "../../lib/format";
import Swal from "sweetalert2";
import axios from "axios";

class EventForm extends Component {

  constructor(props) {
    super(props); // auth, navigate, id
    this.handleSubmit = this.handleSubmit.bind(this);
    if (this.props.id != null) {
      this.state = {info: null, loaded: false};
      this.fetchInfo();
    } else {
      this.state = {info: {}, loaded: true};
    }
  }

  fetchInfo() {
    axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/event/info`, {
      params: {
        id: this.props.id
      },
      headers: {
        'Authorization': `Bearer ${this.props.auth.getToken()}` 
      }
    })
    .then(response => this.setState({info: response.data, loaded: true}));
  }

  getFormVersion() {
    if (!this.state.loaded) {
      return "loading";
    }
    if (this.state.info != null) {
      return "edit";
    } else {
      return "create";
    }
  }

  getFormStructure() {
    if (!this.state.loaded) {
      return this.getLoadingStructure();
    }
    return this.getLoadedStructure();
  }

  getLoadedStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              label: "Event Information",
              fields: [
                {
                  type: "text",
                  attributes: {
                    id: "title",
                    label: "Title",
                    placeholder: "Wild Wednesday Run",
                    default: this.state.info.title || null,
                    required: true
                  }
                },
                {
                  type: "dropselect",
                  attributes: {
                    id: "location",
                    label: "Location",
                    required: false,
                    default: (
                      Object.keys(this.state.info).length === 0 ? null :
                      this.state.info.placeName === "UCT Information Centre" ? "1" :
                      this.state.info.placeName === "UCT Fitness Centre" ? "2" :
                      this.state.info.placeName === "Microsoft Teams" ? "3" :
                      this.state.info.placeName === "Zoom" ? "4" :
                      this.state.info.placeName != null ? "5" :
                      null
                    ),
                    options: [
                      {label: "N/A", value: ""},
                      {label: "UCT Information Centre", value: 1},
                      {label: "UCT Fitness Centre", value: 2},
                      {label: "Microsoft Teams", value: 3},
                      {label: "Zoom", value: 4},
                      {label: "Other", value: 5}
                    ]
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "placeName",
                    label: "Place Name",
                    placeholder: "Newlands Forest Parking",
                    default: (
                      this.state.info.placeName != null &&
                      this.state.info.placeName !== "UCT Information Centre" &&
                      this.state.info.placeName !== "UCT Fitness Centre" &&
                      this.state.info.placeName !== "Microsoft Teams" &&
                      this.state.info.placeName !== "Zoom" ?
                      this.state.info.placeName :
                      null
                    ),
                    required: true,
                    visible: "location={5}"
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "meetingURL",
                    label: "Online Meeting Link",
                    placeholder: "https://teams.microsoft.com/l/meetup-join/...",
                    validate: "url",
                    default: (
                      this.state.info.placeName === "Microsoft Teams" ||
                      this.state.info.placeName === "Zoom" ?
                      this.state.info.placeURL :
                      null
                    ),
                    required: false,
                    visible: "location={[3,4]}"
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "placeURL",
                    label: "Google Maps Link",
                    placeholder: "https://goo.gl/maps/zAE5La8JDRLH8xRo8",
                    validate: "url",
                    default: (
                      this.state.info.placeName != null &&
                      this.state.info.placeName !== "UCT Information Centre" &&
                      this.state.info.placeName !== "UCT Fitness Centre" &&
                      this.state.info.placeName !== "Microsoft Teams" &&
                      this.state.info.placeName !== "Zoom" ?
                      this.state.info.placeURL :
                      null
                    ),
                    required: false,
                    visible: "location={5}"
                  }
                },
                {
                  type: "datetime",
                  attributes: {
                    id: "start",
                    label: "Start",
                    variation: "datetime",
                    min: this.state.info === null ? "today" : null,
                    default: (
                      this.state.info.startTimestamp != null ?
                      Format.datetime(this.state.info.startTimestamp, "iso") :
                      null
                    ),
                    required: true
                  }
                },
                {
                  type: "datetime",
                  attributes: {
                    id: "end",
                    label: "End",
                    variation: "datetime",
                    min: this.state.info === null ? "today" : null,
                    default: (
                      this.state.info.endTimestamp != null ?
                      Format.datetime(this.state.info.endTimestamp, "iso") :
                      null
                    ),
                    required: true
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "duration",
                    label: "Duration",
                    placeholder: "5-6 hours",
                    default: this.state.info.duration || null,
                    required: true
                  }
                },
                {
                  type: "dropselect",
                  attributes: {
                    id: "portfolio",
                    label: "Portfolio",
                    required: true,
                    default: this.state.info.portfolioId || 5,
                    options: [
                      {label: "Hiking", value: 5},
                      {label: "Climbing", value: 6},
                      {label: "Trail Running", value: 7},
                      {label: "TED", value: 8},
                      {label: "Siyenyuka", value: 9},
                      {label: "Huts & Ski", value: 10},
                      {label: "Zuurberg", value: 11},
                      {label: "Conservation", value: 12},
                      {label: "Climbing Wall", value: 14},
                      {label: "Socials", value: 15}
                    ]
                  }
                },
                {
                  type: "dropselect",
                  attributes: {
                    id: "difficulty",
                    label: "Difficulty",
                    required: false,
                    default: this.state.info.difficulty || null,
                    options: [
                      {label: "N/A", value: ""},
                      {label: "All Levels!", value: 1},
                      {label: "Beginner", value: 2},
                      {label: "Easy/Moderate", value: 3},
                      {label: "Moderate", value: 4},
                      {label: "Moderate/Difficult", value: 5},
                      {label: "Difficult", value: 6},
                      {label: "Extremely Difficult", value: 7}
                    ]
                  }
                },
                {
                  type: "richtext",
                  attributes: {
                    id: "description",
                    label: "Description",
                    placeholder: "Join us this weekend...",
                    default: this.state.info.description || null,
                    required: true
                  }
                }
              ]
            },
            {
              label: "Sign-Up Options",
              fields: [
                {
                  type: "radioselect",
                  attributes: {
                    id: "signup",
                    label: "Sign-Up Type",
                    required: true,
                    default: (
                      Object.keys(this.state.info).length === 0 ? null :
                      this.state.info.signup === 0 ? 0 :
                      this.state.info.openTimestamp === null ? 1 :
                      2
                    ),
                    options: [
                      {label: "No Sign-Up", value: 0, icon: "cross"},
                      {label: "Immediate", value: 1, icon: "tick"},
                      {label: "Scheduled", value: 2, icon: "tick"}
                    ]
                  }
                },
                {
                  type: "datetime",
                  attributes: {
                    id: "open",
                    label: "Open Time",
                    variation: "datetime",
                    min: this.state.info === null ? "today" : null,
                    default: (
                      this.state.info.openTimestamp != null ?
                      Format.datetime(this.state.info.openTimestamp, "iso") :
                      null
                    ),
                    required: true,
                    visible: "signup={2}"
                  }
                },
                {
                  type: "datetime",
                  attributes: {
                    id: "close",
                    label: "Close Time",
                    variation: "datetime",
                    min: this.state.info === null ? "today" : null,
                    default: (
                      this.state.info.closeTimestamp != null ?
                      Format.datetime(this.state.info.closeTimestamp, "iso") :
                      null
                    ),
                    required: false,
                    visible: "signup={2}"
                  }
                },
                {
                  type: "radioselect",
                  attributes: {
                    id: "limit",
                    label: "Participant Limit",
                    required: true,
                    visible: "signup={[1,2]}",
                    default: (
                      Object.keys(this.state.info).length === 0 ? null :
                      this.state.info.signup === 1 &&
                      this.state.info.capacityLimit !== null &&
                      this.state.info.overflowLimit !== null ?
                      1 :
                      0
                    ),
                    options: [
                      {label: "Unlimited Spots", value: 0, icon: "tick"},
                      {label: "Limited Spots", value: 1, icon: "tick"}
                    ]
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "capacity",
                    label: "Guaranteed Spots",
                    placeholder: "10",
                    validate: "int[1-100]",
                    required: true,
                    default: this.state.info.capacityLimit || null,
                    visible: "limit={1}"
                  }
                },
                {
                  type: "text",
                  attributes: {
                    id: "overflow",
                    label: "Waiting List Spots",
                    placeholder: "5",
                    validate: "int[0-100]",
                    required: true,
                    default: this.state.info.overflowLimit || null,
                    visible: "limit={1}"
                  }
                }
              ]
            },
          ]
        }
      ],
      fields: [
        {
          type: "submitbutton"
        }
      ]
    };
  }

  getLoadingStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              fields: [
                {
                  type: "information",
                  attributes: {
                    id: "info",
                    title: "Loading Event...",
                    text: "Please wait while the event information is loaded."
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  handleSubmit(valid, fields) {
    if (valid) {
      Swal.fire({
        title: "Submitting...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      // set placeName and placeURL parameter
      let placeName, placeURL;
      switch (fields["location"].value) {
        case "1":
          placeName = "UCT Information Centre";
          placeURL = "https://goo.gl/maps/g6X5zCswFoWdkvas9";
          break;
        case "2":
          placeName = "UCT Fitness Centre";
          placeURL = "https://goo.gl/maps/RRu2peEmQZsLrjee6";
          break;
        case "3":
          placeName = "Microsoft Teams";
          placeURL = fields["meetingURL"].value;
          break;
        case "4":
          placeName = "Zoom";
          placeURL = fields["meetingURL"].value;
          break;
        case "5":
          placeName = fields["placeName"].value
          placeURL = fields["placeURL"].value;
          break;
        default:
          placeName = null;
          break;
      }
      let formData = {
        title: fields["title"].value,
        placeName,
        placeURL,
        start: fields["start"].value,
        end: fields["end"].value,
        portfolio: fields["portfolio"].value,
        duration: fields["duration"].value,
        difficulty: fields["difficulty"].value,
        description: fields["description"].value,
        signup: fields["signup"].value > 0 ? 1 : 0,
        open: fields["open"].value,
        close: fields["close"].value,
        limit: fields["limit"].value,
        capacity: fields["capacity"].value,
        overflow: fields["overflow"].value
      };
      if (this.props.id == null) {
        axios.post(`/api/v${process.env.REACT_APP_API_VERSION}/event/create`, formData,
          {
            headers: {
              'Authorization': `Bearer ${this.props.auth.getToken()}` 
            }
          })
        .then(response => {
          if (response.status === 204) {
            Swal.close();
            Swal.fire({
              title: "Event Created",
              text: "You have successfully created a new event!",
              icon: "success",
              confirmButtonColor: "#1C53A4"
            }).then(() => {
              this.props.navigate("/event/manager");
            });
          }
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            title: "Submission Failed",
            text: "Your event was not created, please try again.",
            icon: "error",
            confirmButtonColor: "#1C53A4"
          });
          console.log(error);
        });
      } else {
        formData.id = this.props.id;
        axios.put(`/api/v${process.env.REACT_APP_API_VERSION}/event/edit`, formData,
          {
            headers: {
              'Authorization': `Bearer ${this.props.auth.getToken()}` 
            }
          })
        .then(response => {
          if (response.status === 204) {
            Swal.close();
            Swal.fire({
              title: "Changes Saved",
              text: "You have successfully updated the event!",
              icon: "success",
              confirmButtonColor: "#1C53A4"
            }).then(() => {
              this.props.navigate("/event/manager");
            });
          }
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            title: "Submission Failed",
            text: "Your event was not updated, please try again.",
            icon: "error",
            confirmButtonColor: "#1C53A4"
          });
          console.log(error);
        });
      }
    }
  }

  render() {
    return <Form structure={this.getFormStructure()} version={this.getFormVersion()} onSubmit={this.handleSubmit}/>;
  }

}

export default EventForm;