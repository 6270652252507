import React, { Component } from 'react';

import Event from '../../../lib/event';
import Clock from '../../../lib/clock';
import humanizeDuration from 'humanize-duration';
import clsx from 'clsx';

import styles from "./styles/eventselect.module.css";
import MinorSection from '../Section/MinorSection';
import Format from '../../../lib/format';

class EventSelectField extends Component {

  constructor(props) {
    super(props); // id, events, value, valid, onChange
    this.handleChange = this.handleChange.bind(this);
    this.interval = null;
    this.state = {updated: Clock.time()};
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({updated: Clock.time()});
    }, 1000);
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChange(value) {
    const event = this.props.events.find(e => e.id === value);
    if (this.openEventsFilter(event)) {
      this.props.onChange(this.props.id, value);
    }
  }

  openEventsFilter(event) {
    return Event.isOpen(event) 
    || Event.isFull(event)
    || (Event.isPending(event) && Clock.time() >= (event.openTimestamp - 60*1000))
  }

  upcomingEventsFilter(event) {
    return Event.isPending(event)
    && Clock.time() < (event.openTimestamp - 60*1000)
  }

  closedEventsFilter(event) {
    return Event.isClosed(event)
  }

  renderEvents(label, filter) {
    const events = this.props.events.filter(filter);
    if(events.length > 0) {
      return (
        <EventList
          label={label}
          events={events}
          selectedID={this.props.value}
          onItemClick={this.handleChange}/>
      );
    }
  }

  render() {
    return (
      <div
        className={clsx({
          [styles.invalid]: (this.props.valid !== undefined && !this.props.valid)
        })}>
        <div className={styles.hint}>
          Please select an event from the list below *
        </div>
        {this.renderEvents("Open", this.openEventsFilter)}
        {this.renderEvents("Upcoming", this.upcomingEventsFilter)}
        {this.renderEvents("Closed", this.closedEventsFilter)}
      </div>
    );
  }
}

class EventList extends React.Component {

  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(id) {
    this.props.onItemClick(id);
  }

  compare(event1, event2) {
    if (event1.startTimestamp < event2.startTimestamp) {
      return -1;
    }
    if (event1.startTimestamp > event2.startTimestamp) {
      return 1;
    }
    return 0;
  }

  render() {
    const events = this.props.events.sort(this.compare);
    return (
      <MinorSection
        label={this.props.label}>
        <ul className={styles.list}>
          {
            events.map((props, idx) => (
              <EventItem
                key={idx}
                id={props.id}
                title={props.title}
                startTimestamp={props.startTimestamp}
                endTimestamp={props.endTimestamp}
                openTimestamp={props.openTimestamp}
                closeTimestamp={props.closeTimestamp}
                capacityLimit={props.capacityLimit}
                overflowLimit={props.overflowLimit}
                memberCount={props.memberCount}
                selected={props.id === this.props.selectedID}
                onClick={this.handleItemClick}
                />
            ))
          }
        </ul>
      </MinorSection>
    );
  }

}

class EventItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick(this.props.id);
  }

  getStatus() {
    if (Event.isOpen(this.props)) {
      return "open";
    } else if (Event.isFull(this.props)) {
      return "full";
    } else if (Event.isPending(this.props)) {
      return "pending";
    } else {
      return "closed";
    }
  }

  getStatusText(status) {
    switch (status) {
      case "open":
        if (this.props.capacityLimit === null) {
          return "unlimited spots available";
        } else {
          const spots = this.props.capacityLimit - this.props.memberCount;
          const unit = Format.unit(spots, "guaranteed spot", "guaranteed spots");
          return unit + " available";
        }
      case "full":
        const spots = this.props.capacityLimit + this.props.overflowLimit - this.props.memberCount;
        const unit = Format.unit(spots, "waiting list spot", "waiting list spots");
        return unit + " available";
      case "pending":
        return `going live in ${humanizeDuration(this.props.openTimestamp - Clock.time(), {largest: 1, round: true})}`;
      default:
        return "no spots available";
    }
  }

  render() {
    const status = this.getStatus();
    return (
      <li
        className={clsx({
          [styles.item]: true,
          [styles.selected]: this.props.selected,
          [styles.open]: status === "open",
          [styles.full]: status === "full",
          [styles.pending]: status === "pending",
          [styles.closed]: status === "closed"
        })}
        onClick={this.handleClick}>
        <div className={styles.title}>
          {this.props.title}
        </div>
        <div className={styles.date}>
          {timestamps2dates(this.props.startTimestamp, this.props.endTimestamp)}
        </div>
        <div className={styles.status}>
          {this.getStatusText(status)}
        </div>
      </li>
    );
  }

}

function timestamps2dates(start, end) {
  const s = new Date(start);
  const e = new Date(end);
  const day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const month = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  if(s.getFullYear() === e.getFullYear() && s.getMonth() === e.getMonth() && s.getDate() === e.getDate()) {
    return day[s.getDay()] + ", " + s.getDate() + " " + month[s.getMonth()];
  } else if (s.getFullYear() === e.getFullYear()) {
    return day[s.getDay()] + ", " + s.getDate() + " " + month[s.getMonth()] + " - " + day[e.getDay()] + ", " + e.getDate() + " " + month[e.getMonth()];
  } else {
    return day[s.getDay()] + ", " + s.getDate() + " " + month[s.getMonth()] + " " + s.getFullYear() + " - " + day[e.getDay()] + ", " + e.getDate() + " " + month[e.getMonth()] + " " + e.getFullYear();
  }
}

export default EventSelectField;