import React, { Component } from 'react';

import clsx from 'clsx';

import styles from './styles/wrapper.module.css';

class Wrapper extends Component {

  // props: label, for, required, valid, visible, disabled

  render() {
    return (
      <div
        className={clsx({
          [styles.container]: true,
          [styles.required]: (this.props.required != null && this.props.required),
          [styles.invalid]: (this.props.valid != null && !this.props.valid),
          [styles.hidden]: (this.props.visible != null && !this.props.visible),
          [styles.disabled]: (this.props.disabled != null && this.props.disabled)
        })}>
        <div className={styles.label}>
          <label htmlFor={this.props.for}>{this.props.label}</label>
        </div>
        {this.props.children}
      </div>
    );
  }

}

export default Wrapper;