import { Component } from "react";
import Page from "../components/Page";

class Error404Page extends Component {

  componentDidMount() {
    document.title = "Page Not Found | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <h1>Page Not Found</h1>
        <p>The page at {window.location.pathname} does not exist.</p>
      </Page>
    );
  }

}

export default Error404Page;