import React, { Component } from "react";
import Page from "../../components/Page";
import EventForm from "../../components/EventForm";
import { useAuth } from "../../lib/auth";
import { useNavigate } from "react-router-dom";

class EventCreatePage extends Component {

  componentDidMount() {
    document.title = "Create Event | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <h1>Create Event</h1>
        <CreateFormWrapper/>
      </Page>
    );
  }

}

function CreateFormWrapper(props) {
  let auth = useAuth();
  let navigate = useNavigate();
  return <EventForm {...props} auth={auth} navigate={navigate}/>
}

export default EventCreatePage;