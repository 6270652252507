import React, { Component } from 'react';
import Wrapper from "./Wrapper";

import clsx from 'clsx';
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";

import styles from "./styles/datetime.module.css";

class DateTimeField extends Component {

  constructor(props) {
    super(props); // id, label, value, placeholder, required, valid, visible, variation, min, max, onChange
    this.ref = React.createRef();
    this.picker = null;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedDates, dateStr, instance) {
    if (this.props.onChange != null) {
      this.props.onChange(this.props.id, dateStr);
    }
  }

  getMaxDate() {
    return this.props.max;
  }

  getVariation() {
    if (this.props.variation != null && ["datetime", "date", "time"].includes(this.props.variation)) {
      return this.props.variation;
    } else {
      return "datetime";
    }
  }

  getFormat() {
    const variation = this.getVariation();
    switch (variation) {
      case "date":
        return "Y-m-d";
      case "time":
        return "H:i:S";
      case "datetime":
      default:
        return "Y-m-d H:i:S";
    }
  }

  render() {
    const variation = this.getVariation();
    return (
      <Wrapper
        label={this.props.label}
        for={this.props.id}
        required={this.props.required}
        valid={this.props.valid}
        visible={this.props.visible}>
        <Flatpickr
          id={this.props.id}
          name={this.props.id}
          className={clsx({
            [styles.both]: variation === "datetime",
            [styles.date]: variation === "date",
            [styles.time]: variation === "time",
            [styles.invalid]: !this.props.valid
          })}
          value={this.props.value === null ? "" : this.props.value}
          placeholder={this.props.placeholder}
          options={{
            enableTime: ["datetime", "time"].includes(variation),
            noCalendar: variation === "time",
            defaultHour: 8,
            minDate: this.props.min,
            maxDate: this.props.max,
            dateFormat: this.getFormat(),
            position: "auto right",
            onChange: this.handleChange
          }}/>
      </Wrapper>
    );
  }

}

export default DateTimeField;