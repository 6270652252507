import React, { Component } from 'react';
import Section from '.';

class MinorSection extends Component {

  // props: label, visible, width

  render() {
    return (
      <Section
        type='minor'
        label={this.props.label}
        visible={this.props.visible}
        width={this.props.width}>
        {this.props.children}
      </Section>
    );
  }

}

export default MinorSection;