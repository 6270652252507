import React, { Component } from 'react';
import Wrapper from './Wrapper';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import clsx from 'clsx';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from './styles/richtext.module.css';

class RichTextField extends Component {
  
  constructor(props) {
    super(props); // id, label, value, placeholder, required, valid, visible, onChange, onBlur
    if (props.value != null) {
      const contentBlock = htmlToDraft(props.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        this.state = {editorState: EditorState.createWithContent(contentState)};
      }
    } else {
      this.state = {editorState: EditorState.createEmpty()};
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getValue(editorState) {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
    if (value === "<p></p>") {
      return "";
    } else {
      return value;
    }
  }

  handleChange(e) {
    this.setState({editorState: e});
    if (this.getValue(this.state.editorState) === this.getValue(e)) {
      return;
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.props.id, this.getValue(e));
    }
  }

  handleBlur(e) {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(this.props.id, this.getValue(this.state.editorState));
    }
  }

  render() {
    const { editorState } = this.state;
    return (
      <Wrapper 
        label={this.props.label}
        for={"rdw-wrapper-" + this.props.id}
        required={this.props.required}
        valid={this.props.valid}
        visible={this.props.visible}>
        <Editor
          wrapperId={this.props.id}
          editorState={editorState}
          wrapperClassName={clsx({
            [styles.wrapper]: true,
            [styles.invalid]: !this.props.valid
          })}
          toolbarClassName={styles.toolbar}
          editorClassName={styles.editor}
          toolbar={{
            options: ["inline", "colorPicker", "list", "link", "remove"],
            inline: {
              strikethrough: {className: styles.hidden},
              monospace: {className: styles.hidden},
              superscript: {className: styles.hidden},
              subscript: {className: styles.hidden}
            },
            list: {
              inDropdown: true
            },
            link: {
              inDropdown: true
            }
          }}
          placeholder={this.props.placeholder}
          onEditorStateChange={this.handleChange}
          onBlur={this.handleBlur}/>
      </Wrapper>
    );
  }

}

export default RichTextField;