import React, { Component } from 'react';
import MajorSection from './Section/MajorSection';
import MinorSection from './Section/MinorSection';
import InformationField from './Field/InformationField';
import TextField from './Field/TextField';
import PasswordField from './Field/PasswordField';
import RichTextField from './Field/RichTextField';
import DateTimeField from './Field/DateTimeField';
import DropSelectField from './Field/DropSelectField';
import RadioSelectField from './Field/RadioSelectField';
import EventSelectField from './Field/EventSelectField';
import TableField from './Field/TableField';
import RegularButton from './Button/RegularButton';
import SubmitButton from './Button/SubmitButton';

import validator from 'validator';

class Form extends Component {

  constructor(props) {
    super(props); // structure, version, width, onSubmit
    this.fields = {};
    this.version = null;
    this.valid = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(id, value) {
    if (this.fields[id].value === value || value.length === 0) {
      this.fields[id].value = null;
    } else {
      this.fields[id].value = value;
    }
    if (!this.fields[id].valid) {
      this.validateField(id);
    }
    this.forceUpdate();
  }

  handleBlur(id, value) {
    this.fields[id].value = value.trim();
    this.validateField(id);
    this.forceUpdate();
  }

  handleSubmit() {
    this.validateForm();
    this.props.onSubmit(this.valid, this.fields);
  }

  isFieldVisible(condition) {
    if (condition === undefined || condition === null) {
      return true;
    } else if (typeof condition === "boolean") {
      return condition;
    } else {
      const id = condition.split("=")[0];
      let rawValue = condition.split("=")[1];
      let values = [];
      if (rawValue.startsWith("{") && rawValue.endsWith("}")) {
        rawValue = rawValue.substring(1, rawValue.length-1);
        if (rawValue.startsWith("[") && rawValue.endsWith("]")) {
          rawValue = rawValue.substring(1, rawValue.length-1);
          rawValue.split(",").forEach(value => values.push(parseInt(value))); 
        } else {
          values.push(parseInt(rawValue));
        }
      } else {
        values.push(rawValue);
      }
      return values.includes(parseInt(this.fields[id].value)) && this.isFieldVisible(this.fields[id].visibility);
    }
  }

  validateForm() {
    let valid = true;
    for (const id in this.fields) {
      if (!this.validateField(id)) {
        valid = false;
      }
    }
    this.valid = valid;
    this.forceUpdate();
  }

  validateField(id) {
    const value = (this.fields[id].value === null) ? "" : this.fields[id].value;
    const validation = this.fields[id].validation || "";
    const required = this.fields[id].required;
    let valid = true;
    if (required && value.length === 0 && this.isFieldVisible(this.fields[id].visibility)) {
      valid = false;
    } else if (value.length > 0) {
      if (validation.startsWith("^")) {
        valid = new RegExp(validation).test(value);
      } else if (validation.startsWith("int") && validation.endsWith("]")) {
        const min = parseInt(validation.split("[")[1].split("-")[0]);
        const max = parseInt(validation.split("[")[1].split("-")[1]);
        valid = validator.isInt(value, {min: min, max: max});
      } else if (validation === "int") {
        valid = validator.isInt(value);
      } else if (validation === "phone") {
        valid = validator.isMobilePhone(value);
      } else if (validation === "email") {
        valid = validator.isEmail(value);
      } else if (validation === "url") {
        valid = validator.isURL(value);
      } else if (validation === "password") {
        valid = validator.isStrongPassword(value)
      }
    } 
    this.fields[id].valid = valid;
    return valid;
  }

  extractFields(structure) {
    if (structure.majorSections !== undefined) {
      structure.majorSections.forEach((structure, idx) => this.extractFields(structure));
    } else if (structure.minorSections !== undefined) {
      structure.minorSections.forEach((structure, idx) => this.extractFields(structure));
    } else {
      structure.fields.forEach((field, idx) => {
        this.fields[field.attributes.id] = {
          value: (field.attributes.default != null ? field.attributes.default : null),
          required: field.attributes.required || false,
          validation: field.attributes.validate || null,
          visibility: field.attributes.visible || true,
          valid: true
        };
      });
    }
    this.version = this.props.version;
  }

  renderMajorSections(majorSections) {
    return majorSections.map((props, idx) => (
      <MajorSection
        key={idx}
        label={props.label}
        width={this.props.width}
        visible={this.isFieldVisible(props.visible)}>
        {(props.minorSections != null) ? this.renderMinorSections(props.minorSections) : <></>}
        {(props.fields != null) ? this.renderFields(props.fields) : <></>}
      </MajorSection>
    ));
  }

  renderMinorSections(minorSections) {
    return minorSections.map((props, idx) => (
      <MinorSection
        key={idx}
        label={props.label}
        visible={this.isFieldVisible(props.visible)}>
        {this.renderFields(props.fields)}
      </MinorSection>
    ));
  }

  renderFields(fields) {
    return fields.map((props, idx) => {
      switch (props.type) {
        case "information":
          return (
            <InformationField
              key={idx}
              id={props.attributes.id}
              title={props.attributes.title}
              text={props.attributes.text}
              visible={this.isFieldVisible(props.attributes.visible)}/>
          );
        case "text":
          return (
            <TextField
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              value={this.fields[props.attributes.id].value}
              placeholder={props.attributes.placeholder}
              valid={this.fields[props.attributes.id].valid}
              required={props.attributes.required}
              disabled={props.attributes.disabled}
              visible={this.isFieldVisible(props.attributes.visible)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onEnter={this.handleSubmit}/>
          );
        case "password":
          return (
            <PasswordField
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              value={this.fields[props.attributes.id].value}
              valid={this.fields[props.attributes.id].valid}
              required={props.attributes.required}
              visible={this.isFieldVisible(props.attributes.visible)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onEnter={this.handleSubmit}/>
          );
        case "richtext":
          return (
            <RichTextField
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              value={this.fields[props.attributes.id].value}
              placeholder={props.attributes.placeholder}
              valid={this.fields[props.attributes.id].valid}
              required={props.attributes.required}
              visible={this.isFieldVisible(props.attributes.visible)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}/>
          );
        case "datetime":
          return (
            <DateTimeField
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              variation={props.attributes.variation}
              value={this.fields[props.attributes.id].value}
              placeholder={props.attributes.placeholder}
              min={props.attributes.min}
              max={props.attributes.max}
              valid={this.fields[props.attributes.id].valid}
              required={props.attributes.required}
              visible={this.isFieldVisible(props.attributes.visible)}
              onChange={this.handleChange}/>
          );
        case "dropselect":
          return (
            <DropSelectField
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              value={this.fields[props.attributes.id].value}
              valid={this.fields[props.attributes.id].valid}
              options={props.attributes.options}
              required={props.attributes.required}
              visible={this.isFieldVisible(props.attributes.visible)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}/>
          );
        case "radioselect":
          return (
            <RadioSelectField
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              value={this.fields[props.attributes.id].value}
              valid={this.fields[props.attributes.id].valid}
              options={props.attributes.options}
              required={props.attributes.required}
              visible={this.isFieldVisible(props.attributes.visible)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}/>
          );
        case "eventselect":
          return (
            <EventSelectField
              key={idx}
              id={props.attributes.id}
              events={props.attributes.events}
              value={this.fields[props.attributes.id].value}
              valid={this.fields[props.attributes.id].valid}
              onChange={this.handleChange}/>
          );
        case "table":
          return (
            <TableField
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              columns={props.attributes.columns}
              rows={props.attributes.rows}
              value={this.fields[props.attributes.id].value}
              valid={this.fields[props.attributes.id].valid}
              required={props.attributes.required}
              visible={this.isFieldVisible(props.attributes.visible)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}/>
          );
        case "regularbutton":
          return (
            <RegularButton
              key={idx}
              id={props.attributes.id}
              label={props.attributes.label}
              onClick={props.attributes.onClick}/>
          );
        case "submitbutton":
          return (
            <SubmitButton
              key={idx}
              label={(props.attributes ? props.attributes.label : null)}
              onClick={this.handleSubmit}/>
          );
        default:
          return <div key={idx}>Nonexistant Field Type</div>;
      }
    });
  }

  render() {
    if (this.version !== this.props.version) {
      this.extractFields(this.props.structure);
    }
    let majorStructure = (<></>);
    if (this.props.structure.majorSections !== undefined) {
      majorStructure = this.renderMajorSections(this.props.structure.majorSections);
    } 
    let minorStructure = (<></>);
    if (this.props.structure.minorSections !== undefined) {
      minorStructure = this.renderMinorSections(this.props.structure.minorSections);
    } 
    let fieldStructure = (<></>);
    if (this.props.structure.fields !== undefined) {
      fieldStructure = this.renderFields(this.props.structure.fields);
    }
    return (
      <>
        {majorStructure}
        {minorStructure}
        {fieldStructure}
      </>
    );
  }

}

export default Form;