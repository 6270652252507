import React, { Component } from 'react';
import Wrapper from './Wrapper';

import clsx from 'clsx';

import styles from './styles/table.module.css';

class TableField extends Component {

  constructor(props) {
    super(props); // id, label, columns, rows, value, required, valid, visible, onChange, onBlur
    this.state = {selected: this.props.value};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (value === this.props.value) {
      value = null;
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.props.id, value);
    }
    this.setState({selected: value});
    this.handleBlur(value);
  }

  handleBlur(value) {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(this.props.id, value);
    }
  }

  render(){
    return (
      <Wrapper 
        label={this.props.label}
        for={this.props.id}
        required={this.props.required}
        valid={this.props.valid}
        visible={this.props.visible}>
        <div className={styles.wrapper}>
          <table className={styles.table}>
            <thead className={styles.head}>
              <TableHeadRow cells={this.props.columns}/>
            </thead>
            <tbody className={styles.body}>
              {
                this.props.rows.map((props, idx) => (
                  <TableBodyRow 
                    key={idx}
                    id={props.id}
                    highlight={props.highlight}
                    cells={props.cells}
                    selected={props.id === this.state.selected}
                    onClick={this.handleChange}/>
                ))
              }
            </tbody>
          </table>
        </div>
      </Wrapper>
    );
  }

}

function TableHeadRow(props) {
  // props: cells
  return (
    <tr className={styles.row}>
      {
        props.cells.map((cellProps, idx) => (
          <TableHeadCell
            key={idx}
            title={cellProps.title}
            visible={cellProps.visible}/>
        ))
      }
    </tr>
  );
}

class TableBodyRow extends Component {

  constructor(props) {
    super(props); // id, cells, onClick
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const id = (this.props.id == null ? -1 : this.props.id);
    if (this.props.onClick !== undefined) {
      this.props.onClick(id);
    }
  }

  render() {
    return (
      <tr
        className={clsx({
          [styles.row]: true,
          [styles.selected]: this.props.selected
        })}
        onClick={this.handleClick}>
        {
          this.props.cells.map((props, idx) => (
            <TableBodyCell
              key={idx}
              content={props.content}
              highlight={props.highlight ? props.highlight : null}
              visible={props.visible}/>
          ))
        }
      </tr>
    );
  }

}

function TableHeadCell(props) {
  // props: type, title, visible
  return (
    <th 
      className={clsx({
        [styles.cell]: true,
        [styles.hidden]: props.visible != null ? !props.visible : false
      })}>
      {props.title}
    </th>
  );
}

function TableBodyCell(props) {
  // props: type, content, highlight, visible
  if (typeof props.content === "string") {
    return (
      <td
        className={clsx({
          [styles.cell]: true,
          [styles[props.highlight]]: props.highlight != null,
          [styles.hidden]: props.visible != null ? !props.visible : false
        })}
        dangerouslySetInnerHTML={{__html: props.content}}/>
    );
  } else {
    return (
      <td
        className={clsx({
          [styles.cell]: true,
          [styles[props.highlight]]: props.highlight != null,
          [styles.hidden]: props.visible != null ? !props.visible : false
        })}>
        {props.content}
      </td>
    );
  }
}

export default TableField;