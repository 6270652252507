import React from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import styles from './auth.module.css';
import Clock from './clock';
import Page from '../components/Page';
import SuperLink from '../components/SuperLink';

function AuthProvider({ children }) {

  let navigate = useNavigate();

  let logIn = (username, password) => {
    Swal.fire({
      title: "Logging in...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    return axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/auth/login`, {
      params: {
        username, 
        password
      }
    })
    .then(response => {
      Swal.close();
      setToken(response.data.token);
      return true;
    })
    .catch(() => {
      Swal.close();
      Swal.fire({
        title: "Login Failed",
        text: "The credentials you entered are invalid, please try again.",
        icon: "error",
        confirmButtonColor: "#1C53A4"
      });
      return false;
    });
  }

  let logOut = () => {
    sessionStorage.removeItem("token");
    navigate("/");
  }

  let setToken = (token) => {
    sessionStorage.setItem("token", token);
  }

  let getToken = () => {
    const token = sessionStorage.getItem("token");
    if (token == null) {
      return null;
    } else {
      const expiry = readToken(token)[1]["exp"];
      const now = Math.floor(Clock.time()/1000);
      // if the token has expired, log the user out
      if (now >= expiry) {       
        Swal.fire({
          title: "Session Expired",
          text: "Your session has expired, please log in again.",
          icon: "warning",
          confirmButtonColor: "#1C53A4"
        })
        .then(logOut);
        return null;
      } 
      // if the token is going to expire soon, refresh it for next request
      else if (now >= expiry - 15 * 60) { 
        axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/auth/refresh`, {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        })
        .then(response => {
          setToken(response.data.token);
        });
      }
      return token;
    }
  }

  let readToken = (token) => {
    if (token == null) {
      return null;
    }
    token = token.split(".");
    const enc_header = token[0];
    const enc_payload = token[1];
    const signature = token[2];
    let base64UrlDecode = content => window.atob(content.replace("-", "+").replace("_", "/"));
    const header = JSON.parse(base64UrlDecode(enc_header));
    const payload = JSON.parse(base64UrlDecode(enc_payload));
    return [header, payload, signature];
  }

  let getClaim = (key) => {
    const token = getToken();
    const payload = readToken(token)[1];
    if (key in payload) {
      return payload[key];
    } else {
      console.error(`Claim '${key}' does not exist in token.`);
    }
  }

  let getRole = () => {
    const token = getToken();
    if (token == null) {
      return "public";
    } else {
      return getClaim("role");
    }
  } 

  let value = {logIn, logOut, getToken, getClaim, getRole};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

let AuthContext = React.createContext(null);

function useAuth() {
  return React.useContext(AuthContext);
}

function NavigationBar() {
  let auth = useAuth();
  if (auth.getRole() === "public") {
    return <React.Fragment/>;
  }
  return (
    <nav className={styles.nav}>
      <Link to="/">
        <div className={styles.logo}></div>
        <div className={styles.title}>{process.env.REACT_APP_PROJECT_NAME}</div>
      </Link>
      <div className={styles.user}>
        <strong>User:</strong> { auth.getClaim("user") + " | "}
        <SuperLink to="/" onClick={auth.logOut}>Log Out</SuperLink> {" "}
      </div>
    </nav>
  );
}

function RequireAuth({ children, roles }) {
  let auth = useAuth();
  let location = useLocation();
  if (auth.getRole() === "public") {
    return <Navigate to="/login" state={{ from: location }} replace/>;
  } else if (roles != null && !roles.includes(auth.getRole())) {
    return (
      <Page>
        <h1>Access Denied</h1>
        <p>You do not have the required permissions to access this page.</p>
      </Page>
      );
  }
  return children;
}

export { useAuth, RequireAuth, NavigationBar };
export default AuthProvider;