import { Component } from "react";
import Page from "../components/Page";
import SuperLink from "../components/SuperLink";

class HomePage extends Component {

  componentDidMount() {
    document.title = process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page align="left">
        <h1>Menu</h1>
        <ul>
          <li><SuperLink to="/event/signup">Event Sign-Ups</SuperLink></li>
          <li><SuperLink to="/event/manager">Event Manager</SuperLink></li>
          <li><SuperLink to="/announcement/generator" roles={["chairperson", "webmaster"]}>Announcement Generator</SuperLink></li>
        </ul>
      </Page>
    );
  }

}

export default HomePage;