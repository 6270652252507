import { NavigationBar, useAuth } from "../../lib/auth";
import clsx from "clsx";
import styles from "./page.module.css";

export default function Page(props) {
  // props: align
  const auth = useAuth();
  let align = props.align || "center";
  return (
    <>
      <NavigationBar/>
      <main className={clsx({
          [styles.wrapper]: true,
          [styles.navPresent]: auth.getRole() !== "public",
          [styles.navAbsent]: auth.getRole() === "public",
          [styles.alignLeft]: align === "left",
          [styles.alignCenter]: align === "center"
        })}>
        {props.children}
      </main>
      <footer className={styles.footer}>
        &copy; UCTMSC&nbsp;&nbsp;|&nbsp;&nbsp;Web: v{process.env.REACT_APP_WEB_VERSION}&nbsp;&nbsp;|&nbsp;&nbsp;API: v{process.env.REACT_APP_API_VERSION}
      </footer>
    </>
  );
}