import axios from "axios";
import { Component } from "react";
import Page from "../../components/Page";
import { useAuth } from "../../lib/auth";
import ReactDOMServer from "react-dom/server";
import styles from "./generator.module.css";
import clsx from "clsx";
import Format from "../../lib/format";
import Clock from "../../lib/clock";

class AnnouncementGeneratorPage extends Component {

  componentDidMount() {
    document.title = "Announcement Generator | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <h1 style={{textAlign: "center"}}>Announcement Generator</h1>
        <AnnouncementGeneratorWrapper/>
      </Page>
    );
  }

}

function AnnouncementGeneratorWrapper(props) {
  let auth = useAuth();
  return <AnnouncementGenerator {...props} auth={auth}/>
}

class AnnouncementGenerator extends Component {
  
  constructor(props) {
    super(props);
    this.state = {events: []};
    this.populateEvents();
  }

  populateEvents() {
    axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/event/list`, {
      params: {
        filter: "announcement"
      },
      headers: {
        'Authorization': `Bearer ${this.props.auth.getToken()}` 
      }
    })
    .then(response => {
      this.setState({events: response.data})
    })
  }

  formatLocation(start, name, url) {
    let title = "";
    let time = "";
    let link = "";
    if (name === null) {
      return <></>;
    } else if (name === "Microsoft Teams" || name === "Zoom") {
      title = "Time";
    } else {
      title = "Meet";
    }
    time = Format.time(start);
    if (url !== null) {
      if (url.length > 50 && (name === "Microsoft Teams" || name === "Zoom")) {
        link = <> &ndash; click <a href={url} rel="noreferrer" target="_blank">here</a> to join meeting</>;
      } else {
        link = <> &ndash; <a href={url} rel="noreferrer" target="_blank">{url}</a></>;
      }
    }
    return <><br/><strong>{title}:</strong> {time} ({name}{link})</>;
  }

  formatDuration(duration) {
    if (duration === null) {
      return <></>;
    } else {
      return <><br/><strong>Duration:</strong> {duration}</>;
    }
  }

  formatDifficulty(difficulty) {
    const ratings = ["All Levels!", "Beginner", "Easy/Moderate", "Moderate", "Moderate/Difficult", "Difficult", "Extremely Difficult"];
    if (difficulty === null) {
      return <></>;
    } else {
      return <><br/><strong>Difficulty: </strong> {ratings[difficulty-1]}</>;
    }
  }

  formatSignUp(signup, capacity, open) {
    if (signup === 0) {
      return <></>;
    } else {
      let capacityDescription = "Unlimited spots";
      if (capacity !== null) {
        capacityDescription = `Limited to ${capacity} people`;
      }
      const now = Clock.time();
      let openDescription = "open now"
      if (open !== null || open > now) {
        const o = new Date(open);
        const n = new Date(now);
        if (o.getFullYear() === n.getFullYear() && o.getMonth() === n.getMonth() && o.getDate() === n.getDate()) {
          openDescription = `opens at ${Format.time(open, "short")} today`;
        } else {
          openDescription = <>opens at {Format.datetime(open, "long")}</>;
        }
      }
      return <><br/><strong>Sign-Up: </strong> {capacityDescription} ({openDescription})</>;
    }
  }

  generate() {
    if (this.state.events.length === 0) {
      return "";
    } else {
      const style_h1 = {
        marginBottom: "10px",
        marginTop: "27px",
        fontSize: "24px",
        fontFamily: "'Times New Roman',serif", 
        fontWeight: "bold",
        textAlign: "center",
        color: "#404040"
      };
      const style_h2_v1 = {
        marginBottom: "10px", 
        marginTop: "27px", 
        fontSize: "20px", 
        fontFamily: "'Times New Roman',serif", 
        fontWeight: "bold",
        textDecoration: "none",
        color: "#000000"
      };
      const style_h2_v2 = {
        marginBottom: "5px", 
        marginTop: "25px", 
        fontSize: "20px", 
        fontFamily: "'Times New Roman',serif", 
        fontWeight: "bold",
        textDecoration: "none",
        color: "#000000"
      };
      const style_h3 = {
        marginBottom: "15px", 
        marginTop: "5px", 
        fontSize: "16px", 
        fontFamily: "'Times New Roman',serif", 
        fontWeight: "normal",
        textDecoration: "none",
        color: "purple"
      };
      const style_a = { /* this addresses header link reformatting issues with Amathuba */
        background: "none",
        width: "auto !important",
        height: "auto !important",
        color: "#000000"
      }
      const style_p_v1 = {
        marginBottom: "10px",
        fontSize: "16px",
        fontFamily: "'Times New Roman',serif", 
        fontWeight: "normal", 
        textDecoration: "none",
        color: "#000000"
      };
      const style_p_v2 = {
        marginBottom: "15px",
        marginTop: "15px",
        fontSize: "14px",
        fontFamily: "'Times New Roman',serif",
        color: "#000000"
      };
      const style_span = {
        fontWeight: "bold",
        fontStyle: "italic"
      };
      const style_ul = {
        fontSize: "16px",
        fontFamily: "'Times New Roman',serif",
        color: "#000000"
      };
      const style_li = {
        marginLeft: "8px"
      };
      const style_div = {
        fontSize: "16px",
        fontFamily: "'Times New Roman',serif",
        fontWeight: "normal", 
        textDecoration: "none",
        color: "#000000"
      };
      return (
        <>
          <h2 style={style_h2_v1}>Upcoming Adventures</h2>
          <p style={style_p_v1}>Please read further in the announcement to see all event details&hellip;</p>
          <ul style={style_ul}>
            {
              this.state.events.map((props, idx) => (
                <li key={idx} style={style_li}><a href={"#event-" + (idx+1)}><strong>{props.title}</strong></a> &ndash; {Format.dates(props.startTimestamp, props.endTimestamp, "long")}</li>
              ))
            }
          </ul>
          <br/>
          <h1 style={style_h1}>EVENTS</h1>
          {
            this.state.events.map((props, idx) => (
              <>
                <h2 style={style_h2_v2}>
                  <a style={style_a} href id={"event-" + (idx+1)} name={"event-" + (idx+1)}>
                    {props.title}
                  </a>
                </h2>
                <h3 style={style_h3}>
                  <strong>Date:</strong> {Format.dates(props.startTimestamp, props.endTimestamp, "long")}
                  {this.formatLocation(props.startTimestamp, props.placeName, props.placeURL)}
                  {this.formatDuration(props.duration)}
                  {this.formatDifficulty(props.difficulty)}
                  {this.formatSignUp(props.signup, props.capacityLimit, props.openTimestamp)}
                </h3>
                <div style={style_div} dangerouslySetInnerHTML={{__html: props.description}}></div>
                {(
                  props.signup === 1 
                  ? <p style={style_p_v2}><span style={style_span}>Sign up on <a href="https://vula.uct.ac.za/portal/site/81791de6-6b18-4b7e-8e5a-9c2e404d1314/page/87bfc10a-bf8e-4988-b99f-233a95f3bd6e" rel="noreferrer" target="_blank">Vula</a> or <a href="https://amathuba.uct.ac.za/d2l/le/lessons/19864/topics/934384" rel="noreferrer" target="_blank">Amathuba</a></span>.</p>
                  : <></>
                )}
              </>
            ))
          }
        </>
      );
    }
  }

  render() {
    if (this.state.events.length === 0) {
      return (
        <p>Cannot generate announcement, there are no pending events.</p>
      );
    }
    return (
      <>
        <AnnouncementPreview announcement={this.generate()}/>
        <AnnouncementSource announcement={this.generate()}/>
      </>
    );
  }

}

function AnnouncementPreview(props) {

  let content = <></>;
  if (props.announcement.length === 0) {
    content = "No Pending Events";
  } else {
    content = props.announcement;
  }

  return (
    <>
      <h3>Preview</h3>
      <div className={styles.container}>{content}</div>
    </>
  );
}

function AnnouncementSource(props) {

  let content = <></>;
  if (props.announcement.length === 0) {
    content = "No Pending Events";
  } else {
    content = ReactDOMServer
      .renderToString(props.announcement)
      .replace(/\sdata-reactroot=""/g, "")
      .replace(/&#x27;/g, "'")
      .replace(/<!-- -->/g, "");
  }

  return (
    <>
      <h3>Source</h3>
      <div className={clsx(styles.container, styles.code)}>{content}</div>
    </>
  );
}

export default AnnouncementGeneratorPage;