import React, { Component } from 'react';
import Wrapper from './Wrapper';

import clsx from 'clsx';

import styles from "./styles/radioselect.module.css";

class RadioSelectField extends Component {

  constructor(props) {
    super(props); // id, label, value, options, required, valid, visible, onChange, onBlur
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.props.id, value);
    }
    this.handleBlur(value);
  }

  handleBlur(value) {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(this.props.id, value);
    }
  }

  render(){
    return (
      <Wrapper
        label={this.props.label}
        for={this.props.id}
        required={this.props.required}
        valid={this.props.valid}
        visible={this.props.visible}>
        <ul 
          id={this.props.id}
          className={styles.list}>
          {this.props.options.map((props, idx) => (
            <RadioItem
              key={idx}
              label={props.label}
              value={props.value}
              icon={props.icon}
              selected={props.value === this.props.value}
              onClick={this.handleChange}/>
          ))}
        </ul>
      </Wrapper>
    );
  }

}

class RadioItem extends Component {

  constructor(props) {
    super(props); // label, value, icon, selected, onClick
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick(this.props.value);
  }

  render() {
    return (
      <li
        className={clsx({
          [styles.item]: true,
          [styles.tick]: (this.props.icon === "tick" || this.props.icon === undefined),
          [styles.cross]: this.props.icon === "cross",
          [styles.selected]: this.props.selected
        })}
        onClick={this.handleClick}>
        <div className={styles.label}>
          {this.props.label}
        </div>
      </li>
    );
  }

}

export default RadioSelectField;