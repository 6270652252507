import React, { Component } from 'react';

import clsx from 'clsx';

import styles from './sections.module.css';

class Section extends Component {

  // props: type, label, visible, width

  render() {
    let label = <></>;
    if (this.props.label != null && this.props.label.length > 0) {
      label = (
        <div className={styles.label}>
          {this.props.label}
        </div>
      );
    }
    return (
      <div
        className={clsx({
          [styles.major]: (this.props.type != null && this.props.type === "major"),
          [styles.minor]: (this.props.type != null && this.props.type === "minor"),
          [styles.hidden]: (this.props.visible != null && !this.props.visible)
        })}
        style={{width: (this.props.width != null ? this.props.width : null)}}>
        {label}
        <div className={styles.container}>
          {this.props.children}
        </div>
      </div>
    );
  }

}

export default Section;