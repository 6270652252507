import React, { Component } from 'react';

import clsx from 'clsx';

import styles from './styles/information.module.css';

class InformationField extends Component {

  // props: id, title, text, visible

  render() {
    return (
      <div
        id={this.props.id}
        className={clsx({
          [styles.information]: true,
          [styles.hidden]: (this.props.visible != null && !this.props.visible)
        })}>
        <div className={styles.title}>
          {this.props.title}
        </div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{__html: this.props.text}}>
        </div>
      </div>
    );
  }

}

export default InformationField;