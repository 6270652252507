import React, { Component } from "react";
import { useSearchParams } from "react-router-dom";
import Page from "../../components/Page";
import EventForm from "../../components/EventForm";
import { useAuth } from "../../lib/auth";
import { useNavigate } from "react-router-dom";

class EventEditPage extends Component {

  componentDidMount() {
    document.title = "Edit Event | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <h1>Edit Event</h1>
        <EditFormWrapper/>
      </Page>
    );
  }

}

function EditFormWrapper(props) {
  let auth = useAuth();
  let navigate = useNavigate();
  let searchParams = useSearchParams()[0];
  return <EventForm {...props} auth={auth} navigate={navigate} id={searchParams.get("id")}/>
}

export default EventEditPage;