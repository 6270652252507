import { Component } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../../components/Form";
import Page from "../../components/Page";
import Event from "../../lib/event";
import Format from "../../lib/format";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../../lib/auth";
import SuperLink from "../../components/SuperLink";

class EventManagerPage extends Component {

  componentDidMount() {
    document.title = "Event Manager | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <h1>Event Manager</h1>
        <ManagerFormWrapper/>
      </Page>
    );
  }

}

function ManagerFormWrapper(props) {
  let auth = useAuth();
  let navigate = useNavigate();
  return <ManagerForm {...props} auth={auth} navigate={navigate}/>
}

class ManagerForm extends Component {

  constructor(props) {
    super(props); // auth, navigate
    this.state = {events: [], loaded: false};
    this.populateEvents();
  }

  populateEvents() {
    axios.get(`/api/v${process.env.REACT_APP_API_VERSION}/event/list`, {
      params: {
        sort: "desc"
      },
      headers: {
        'Authorization': `Bearer ${this.props.auth.getToken()}` 
      }
    })
    .then(response => {
      this.setState({events: response.data, loaded: true});
    })
  }  

  eventAction(ioEvent, id, option) {
    ioEvent.preventDefault();
    axios.put(`/api/v${process.env.REACT_APP_API_VERSION}/event/${option}`, 
      {
        id
      },
      {
        headers: {
          'Authorization': `Bearer ${this.props.auth.getToken()}` 
        }
      })
    .then(() => {
      let events = this.state.events;
      events = events.map(e => {
        if (e.id === id) {
          switch (option) {
            case "show":
              e.approved = 1;
              break;
            case "hide":
              e.approved = 0;
              break;
            default:
              break;
          }
        }
        return e;
      })
      this.setState({events});
    })
    .catch((error) => {
      Swal.close();
      Swal.fire({
        title: "Update Failed",
        text: "Your event's visibility was not updated, please try again.",
        icon: "error",
        confirmButtonColor: "#1C53A4"
      });
      console.log(error);
    });
  }
  
  getFormVersion() {
    if (!this.state.loaded) {
      return "loading";
    }
    if (this.state.events.length > 0) {
      return "populated";
    } else {
      return "empty";
    }
  }

  getFormStructure() {
    if (!this.state.loaded) {
      return this.getLoadingStructure();
    }
    if (this.state.events.length > 0) {
      return this.getPopulatedStructure();
    } else {
      return this.getEmptyStructure();
    }
  }

  getPopulatedStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              label: "Options",
              fields: [
                this.getCreateEventButton()
              ]
            },
            {
              label: "Events",
              fields: [
                {
                  type: "table",
                  attributes: {
                    id: "event-table",
                    columns: [
                      {title: "Title"}, 
                      {title: "Portfolio"},
                      {title: "Date"}, 
                      {title: "Sign-Up"},
                      {title: "Actions"}
                    ],
                    rows: this.state.events.map((event, idx) => {
                      const showActionLink = (<SuperLink to="#" onClick={(e) => {this.eventAction(e, event.id, "show");}} roles={["chairperson", "webmaster"]} icon="show" denyStyle="hide" breakBefore={true}>Show</SuperLink>);
                      const hideActionLink = (<SuperLink to="#" onClick={(e) => {this.eventAction(e, event.id, "hide");}} roles={["chairperson", "webmaster"]} icon="hide" denyStyle="hide" breakBefore={true}>Hide</SuperLink>);
                      const hideActionLinkNoBreak = (<SuperLink to="#" onClick={(e) => {this.eventAction(e, event.id, "hide");}} roles={["chairperson", "webmaster"]} icon="hide" denyStyle="hide">Hide</SuperLink>);
                      const editActionLink = (<SuperLink to={`/event/edit?id=${event.id}`} roles={["chairperson", "webmaster"]} ids={[event.createdBy]} icon="edit" denyStyle="hide">Edit</SuperLink>);
                      const viewActionLink = (<SuperLink to={`/event/details?id=${event.id}`} icon="view">View</SuperLink>);
                      let portfolioColor = null;
                      let actionLinks = (<></>);
                      switch (event.portfolio) {
                        case "Hiking":
                          portfolioColor = "red";
                          break;
                        case "Climbing":
                          portfolioColor = "lightblue";
                          break;
                        case "Trail Running":
                          portfolioColor = "lightgreen";
                          break;
                        case "Conservation":
                          portfolioColor = "yellow";
                          break;
                        case "Climbing Wall":
                          portfolioColor = "darkblue";
                          break;
                        case "Huts & Ski":
                          portfolioColor = "purple";
                          break;
                        case "Zuurberg":
                          portfolioColor = "orange";
                          break;
                        case "TED":
                          portfolioColor = "grey";
                          break;
                        case "Siyenyuka":
                          portfolioColor = "brown";
                          break;
                        case "Socials":
                          portfolioColor = "darkgreen";
                          break;
                        default:
                          portfolioColor = null;
                          break;
                      }
                      let statusName = "UNKNOWN";
                      let statusColor = "grey";
                      if (event.approved === 0) {
                        statusName = "PENDING";
                        statusColor = null;
                      } else if (event.signup === 0) {
                        statusName = "NONE";
                        statusColor = null;
                      } else if (Event.isOpen(event)) {
                        statusName = "OPEN";
                        statusColor = "lightgreen";
                      } else if (Event.isFull(event)) {
                        statusName = "OPEN";
                        statusColor = "yellow";
                      } else if (Event.isPending(event)) {
                        statusName = "SCHEDULED";
                        statusColor = "red";
                      } else if (Event.isClosed(event)) {
                        statusName = "CLOSED";
                        statusColor = "grey";
                      }
                      if (["OPEN"].includes(statusName)) {
                        actionLinks = (
                          <>
                            {viewActionLink}
                            {hideActionLink}
                          </>
                        );
                      } else if (["SCHEDULED"].includes(statusName)) {
                        actionLinks = (
                          <>
                            {hideActionLinkNoBreak}
                          </>
                        );
                      } else if (["CLOSED"].includes(statusName)) {
                        actionLinks = (
                          <>
                            {viewActionLink}
                          </>
                        );
                      } else if (["PENDING"].includes(statusName)) {
                        actionLinks = (
                          <>
                            {editActionLink}
                            {showActionLink}
                          </>
                        );
                      }
                      return {
                        id: event.id,
                        cells: [
                          {content: event.title},
                          {content: event.portfolio, highlight: portfolioColor},
                          {content: Format.dates(event.startTimestamp, event.endTimestamp)},
                          {content: statusName, highlight: statusColor},
                          {content: actionLinks}
                        ]
                      }
                    })
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }  
  
  getEmptyStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              label: "Options",
              fields: [
                this.getCreateEventButton()
              ]
            },
            {
              label: "Events",
              fields: [
                {
                  type: "information",
                  attributes: {
                    id: "info",
                    title: "No Events",
                    text: "There are no events on the system, please create an event or check again later."
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  getLoadingStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              label: "Options",
              fields: [
                this.getCreateEventButton()
              ]
            },
            {
              label: "Events",
              fields: [
                {
                  type: "information",
                  attributes: {
                    id: "info",
                    title: "Loading Events...",
                    text: "Please wait while the event list is loaded."
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }

  getCreateEventButton() {
    return {
      type: "regularbutton",
      attributes: {
        id: "create-button",
        label: "Create Event",
        onClick: () => {this.props.navigate("/event/create")}
      }
    };
  }

  render() {
    return <Form structure={this.getFormStructure()} version={this.getFormVersion()} width="1000px"/>;
  }

}

export default EventManagerPage;