class Format {

  static unit(value, singular, plural) {
    if (value === 1) {
      return value + " " + singular;
    } else {
      return value + " " + plural;
    }
  }

  static time(timestamp, style = "long") {
    const d = new Date(timestamp);
    let hours = (d.getHours() % 12 || 12);
    let minutes = d.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let suffix = "am";
    if (d.getHours() >= 12) {
      suffix = "pm";
    }
    if (style === "short" && minutes === "00") {
      return hours + suffix;
    } else {
      return hours + ":" + minutes + suffix;
    }
  }

  static datetime(timestamp, style = "short") {
    const d = new Date(timestamp);
    if (style === "iso") {
      return d.getFullYear() + "-" + zeroPrefix(d.getMonth()+1) + "-" + zeroPrefix(d.getDate()) + " " + zeroPrefix(d.getHours()) + ":" + zeroPrefix(d.getMinutes()) + ":" + zeroPrefix(d.getSeconds());
    } else if (style === "short") {
      const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return d.getDate() + " " + month[d.getMonth()] + " " + d.getFullYear();
    } else if (style === "long") {
      const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return <>{Format.time(timestamp, "short")} on {weekday[d.getDay()]}, {ordinalSuffix(d.getDate())} {month[d.getMonth()]}</>;
    }
  }

  static dates(start, end, style = "short") {
    const s = new Date(start);
    const e = new Date(end);
    if (style === "short") {
      const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      if (s.getFullYear() === e.getFullYear() && s.getMonth() === e.getMonth() && s.getDate() === e.getDate()) {
        return e.getDate() + " " + month[e.getMonth()] + " " + e.getFullYear();
      } else if (s.getFullYear() === e.getFullYear() && s.getMonth() === e.getMonth()) {
        return s.getDate() + " - " + e.getDate() + " " + month[e.getMonth()] + " " + e.getFullYear();
      } else if (s.getFullYear() === e.getFullYear()) {
        return s.getDate() + " " + month[s.getMonth()] + " - " + e.getDate() + " " + month[e.getMonth()] + " " + e.getFullYear();
      } else {
        return s.getDate() + " " + month[s.getMonth()] + " " + s.getFullYear() + " - " + e.getDate() + " " + month[e.getMonth()] + " " + e.getFullYear();
      }
    } else if (style === "long") {
      const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      if (s.getFullYear() === e.getFullYear() && s.getMonth() === e.getMonth() && s.getDate() === e.getDate()) {
        return <>{weekday[e.getDay()]}, {ordinalSuffix(e.getDate())} {month[e.getMonth()]}</>;
      } else if (s.getFullYear() === e.getFullYear()) {
        return <>{weekday[s.getDay()]}, {ordinalSuffix(s.getDate())} {month[s.getMonth()]} to {weekday[e.getDay()]}, {ordinalSuffix(e.getDate())} {month[e.getMonth()]}</>;
      } else {
        return <>{weekday[s.getDay()]}, {ordinalSuffix(s.getDate())} {month[s.getMonth()]} {s.getFullYear()} to {weekday[e.getDay()]}, {ordinalSuffix(e.getDate())} {month[e.getMonth()]} {e.getFullYear()}</>;
      }
    }
  }

}

function zeroPrefix(i) {
  if (i < 10) {
    return "0" + i;
  } else {
    return i;
  }
}

function ordinalSuffix(i) {
  const j = i % 10, k = i % 100;
  if (j === 1 && k !== 11) {
    return <>{i}<sup>st</sup></>;
  }
  if (j === 2 && k !== 12) {
    return <>{i}<sup>nd</sup></>;
  }
  if (j === 3 && k !== 13) {
    return <>{i}<sup>rd</sup></>;
  }
  return <>{i}<sup>th</sup></>;
}

export default Format;