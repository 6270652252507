import React, { Component } from 'react';

import styles from './buttons.module.css';

class RegularButton extends Component {

  constructor(props) {
    super(props); // id, label, onClick
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick();
  }

  render() {
    return (
      <button
        id={this.props.id}
        className={styles.regular}
        onClick={this.handleClick}>
        {this.props.label}
      </button>
    );
  }

}

export default RegularButton;