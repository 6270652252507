import React, { Component } from "react";
import Form from "../components/Form";
import Page from "../components/Page";
import { useAuth } from '../lib/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./login.module.css";

class LoginPage extends Component {

  componentDidMount() {
    document.title = "Log In | " + process.env.REACT_APP_PROJECT_NAME;
  }

  render() {
    return (
      <Page>
        <div className={styles.logo}></div>
        <h1>MSC Login</h1>
        <LoginFormWrapper/>
      </Page>
    );
  }

}

function LoginFormWrapper(props) {
  let auth = useAuth();
  let location = useLocation();
  let navigate = useNavigate();

  let from = "/";
  if (location.state != null && location.state.from != null) {
    from = location.state.from.pathname + (location.state.from.search || "");
  }

  return <LoginForm {...props} auth={auth} navigate={navigate} from={from}/>
}

class LoginForm extends Component {

  constructor(props) {
    super(props); // auth, navigate, from
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getFormStructure() {
    return {
      majorSections: [
        {
          minorSections: [
            {
              fields: [
                {
                  type: "text",
                  attributes: {
                    id: "username",
                    label: "Username",
                    required: true
                  }
                },
                {
                  type: "password",
                  attributes: {
                    id: "password",
                    label: "Password",
                    required: true
                  }
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          type: "submitbutton",
          attributes: {
            label: "Log In"
          }
        }
      ]
    };
  }

  handleSubmit(valid, fields) {
    if (valid) {
      this.props.auth.logIn(fields["username"].value, fields["password"].value)
      .then((result) => {
        if(result) {
          this.props.navigate(this.props.from, {replace: true});
        }
      })
    }
  }

  render() {
    return <Form structure={this.getFormStructure()} onSubmit={this.handleSubmit}/>;
  }

}

export default LoginPage;