import React, { Component } from 'react';

import Wrapper from './Wrapper';

import styles from "./styles/dropselect.module.css";

class DropSelectField extends Component {

  constructor(props) {
    super(props); // id, label, value, options, required, valid, visible, onChange, onBlur
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.props.id, event.target.value);
    }
    this.handleBlur(event.target.value);
  }

  handleBlur(value) {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(this.props.id, value);
    }
  }

  render(){
    return (
      <Wrapper
        label={this.props.label}
        for={this.props.id}
        required={this.props.required}
        valid={this.props.valid}
        visible={this.props.visible}>
        <select
          id={this.props.id}
          name={this.props.id}
          className={styles.select}
          value={this.props.value != null ? this.props.value : ""}
          onChange={this.handleChange}>
          {this.props.options.map((props, idx) => (
            <option
              key={idx}
              value={props.value}>
              {props.label}
            </option>
          ))}
        </select>
      </Wrapper>
    );
  }

}

export default DropSelectField;