import Clock from "./clock";

class Event {

  static isOpen(event) {
    return Clock.time() > event.openTimestamp
      && Clock.time() < event.startTimestamp
      && (event.closeTimestamp === null || Clock.time() < event.closeTimestamp)
      && (event.capacityLimit === null || event.memberCount < event.capacityLimit);
  }

  static isFull(event) {
    return Clock.time() > event.openTimestamp
      && Clock.time() < event.startTimestamp
      && (event.closeTimestamp === null || Clock.time() < event.closeTimestamp)
      && (event.capacityLimit !== null && event.memberCount < event.capacityLimit + event.overflowLimit);
  }

  static isPending(event) {
    return Clock.time() < event.openTimestamp;
  }

  static isClosed(event) {
    return Clock.time() > event.startTimestamp
      || (event.capacityLimit !== null && event.memberCount >= event.capacityLimit + event.overflowLimit)
      || (event.closeTimestamp !== null && Clock.time() > event.closeTimestamp);
  }

}

export default Event;